import qs from "qs";
import axios from "axios";

export async function sendApplyNowEmail(contactForm: {
	name: string;
	contact: string;
	location: string;
	email: string;
	profileLink: string;
	message: string;
}) {
	const url =
		"https://cms.japfaindia.com/wp-content/themes/twentytwentyfour/templates/careerFormJapfa.php";
	const headers = {
		"Content-Type": "application/x-www-form-urlencoded",
	};
	const bodyData = {
		name: contactForm.name,
		phone: contactForm.contact,
		location: contactForm.location,
		email: contactForm.email,
		profileLink: contactForm.profileLink,
		message: contactForm.message,
		sendTo: "hrd.jcipl@japfa.com", // HR email address
		ccTo: "vinayak.darphe@japfa.com",
	};
	const data = qs.stringify(bodyData);
	console.log(data);
	try {
		const response = await axios.post(url, data, { headers });
		return response.data;
	} catch (error) {
		console.error("Error sending apply now form:", error);
		throw error;
	}
}
