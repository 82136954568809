import React from 'react';
import './Footer.css';
import FooterBottomRow from './FooterBottomRow';
const FooterBottom: React.FC = () => {
	return (
		<div className="footer-bottom">
			<FooterBottomRow />
		</div>
	);
};

export default FooterBottom;
