export const supplierContacts = [
	{
		department: "Raw Materials",
		contactPerson: [
			{
				name: "Mahesh Naik",
				email: "mahesh.naik@japfa.com",
			},
			{
				name: "Nigam Mishra",
				email: "nigam.mishra@japfa.com",
			},
		],
	},
	{
		department: "Amino Acid",
		contactPerson: [
			{
				name: "Vanita Kulkarni",
				email: "vanita.kulkarni@japfa.com",
			},
			{
				name: "Ram Joshi",
				email: "ram.joshi@japfa.com",
			},
		],
	},
	{
		department: "Vitamins/Minerals/Premix",
		contactPerson: [
			{
				name: "Vanita Kulkarni",
				email: "vanita.kulkarni@japfa.com",
			},
			{
				name: "Ram Joshi",
				email: "ram.joshi@japfa.com",
			},
		],
	},
	{
		department: "MVC(Animal feed)",
		contactPerson: [
			{
				name: "Vanita Kulkarni",
				email: "vanita.kulkarni@japfa.com",
			},
			{
				name: "Ram Joshi",
				email: "ram.joshi@japfa.com",
			},
		],
	},
	{
		department: "Packing",
		contactPerson: [
			{
				name: "Vidyadhar Bothe",
				email: "vidyadhar.bothe@japfa.com",
			},
			{
				name: "Pramod Mohanty",
				email: "pramod.mohanty@japfa.com",
			},
		],
	},
	{
		department: "Consumables",
		contactPerson: [
			{
				name: "Vidyadhar Bothe",
				email: "vidyadhar.bothe@japfa.com",
			},
			{
				name: "Pramod Mohanty",
				email: "pramod.mohanty@japfa.com",
			},
		],
	},
	{
		department: "Engineering Spare Parts(Mechanical/Electrical)",
		contactPerson: [
			{
				name: "Vinod Patil",
				email: "vinod.patil@japfa.com",
			},
			{
				name: "Dinesh Solanki",
				email: "dinesh.solanki@japfa.com",
			},
			{
				name: "Ambika Dandapat",
				email: "ambika.dandapat@japfa.com",
			},
		],
	},
];
