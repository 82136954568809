import React, { useState, Fragment } from 'react';
import { fadeLeft, fadeRight } from '../../../utils/scrollAnimations';
import { motion } from 'framer-motion';
import { Dialog, Transition } from '@headlessui/react';
import { State } from 'country-state-city';
import { sendApplyNowEmail } from '../../../utils/sendApplyNowEmail';

interface FormData {
	name: string;
	contact: string;
	location: string;
	email: string;
	profileLink: string;
	message: string;
}

interface FormErrors {
	name?: string;
	contact?: string;
	location?: string;
	email?: string;
	profileLink?: string;
}

const JobOpeningsSection = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [formData, setFormData] = useState<FormData>({
		name: '',
		contact: '',
		location: '',
		email: '',
		profileLink: '',
		message: '',
	});
	const [errors, setErrors] = useState<FormErrors>({});

	const closeModal = () => {
		setIsSubmitted(false);
		setIsOpen(false);
		setFormData({
			name: '',
			contact: '',
			location: '',
			email: '',
			profileLink: '',
			message: '',
		});
		setErrors({});
	};

	const openModal = () => {
		setIsOpen(true);
	};

	const handleInputChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
		>,
	) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const validateForm = () => {
		const newErrors: FormErrors = {};
		if (!formData.name.trim()) newErrors.name = 'Name is required';
		if (!formData.contact.trim())
			newErrors.contact = 'Contact number is required';
		else if (!/^[6-9]\d{9}$/.test(formData.contact))
			newErrors.contact = 'Invalid contact number';
		if (!formData.location) newErrors.location = 'Location is required';
		if (!formData.email.trim()) newErrors.email = 'Email is required';
		else if (!/\S+@\S+\.\S+/.test(formData.email))
			newErrors.email = 'Invalid email address';
		if (!formData.profileLink.trim())
			newErrors.profileLink = 'Profile link is required';
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmit = async () => {
		if (validateForm()) {
			try {
				const response = await sendApplyNowEmail({
					name: formData.name,
					contact: formData.contact,
					location: formData.location,
					email: formData.email,
					profileLink: formData.profileLink,
					message: formData.message,
				});
				if (response) {
					setIsSubmitted(true);
					console.log('Form submitted successfully');
					setFormData({
						name: '',
						contact: '',
						location: '',
						email: '',
						profileLink: '',
						message: '',
					});
				}
			} catch (error) {
				setIsSubmitted(true);
				setFormData({
					name: '',
					contact: '',
					location: '',
					email: '',
					profileLink: '',
					message: '',
				});
				console.error('Error submitting form:', error);
				// Optionally, you can set an error state here to show an error message to the user
			}
		} else {
			console.log('Form validation failed');
		}
	};

	const StateData = State.getStatesOfCountry('IN');

	return (
		<div className="my-20 flex items-center justify-between	 gap-10 max-mediumScreen:gap-3 max-sm:flex-col md:my-[120px]">
			{/* image */}
			<div className="mediumScreeen:max-w-[calc(100vw/2)] h-full max-sm:w-full sm:max-w-[calc(100vw/2.4)]">
				<motion.img
					src={`${process.env.PUBLIC_URL}/assets/img/career-openings.webp`}
					alt=""
					className="h-full w-full object-cover"
					initial="hidden"
					variants={fadeLeft}
					whileInView="visible"
				/>
			</div>
			{/* right column  */}
			<motion.div
				className=" w-1/2 max-mediumScreen:pr-8 max-sm:w-full max-sm:pl-8 mediumScreen:mr-[calc((100vw-1352px)/2)]"
				variants={fadeRight}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
			>
				<h2 className=" mb-6 text-4xl font-extrabold text-black  max-md:mb-4 max-sm:mt-8 lg:text-7xl">
					Explore Open Positions
				</h2>
				<p className="mb-6 text-xl">
					Join Our Leading Team - Explore Current Job Openings. Send
					your resume to{' '}
					<a
						className="text-blue-700 underline"
						href="mailto:hrd.jcipl@japfa.com"
					>
						hrd.jcipl@japfa.com
					</a>
				</p>
				<div className="flex flex-wrap items-center gap-6 max-md:items-start max-md:gap-4">
					<div className="">
						<a
							href="https://japfaindiacareers.peoplestrong.com/home"
							target="_blank"
							rel="noopener noreferrer"
						>
							<button className="bg-[#EC691F] p-3 text-white md:px-6 md:py-5">
								Explore job openings
							</button>
						</a>
					</div>

					{/* <div className="  !border !border-[#EC691F] bg-transparent">
						<button
							className="p-3 md:px-6 md:py-5 "
							onClick={openModal}
						>
							Apply Now
						</button>
					</div> */}
					<Transition appear show={isOpen} as={Fragment}>
						<Dialog
							as="div"
							className="relative z-10"
							onClose={closeModal}
						>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<div className="jobForm-custom-backdrop fixed inset-0 bg-black/30" />
							</Transition.Child>

							<div className="fixed inset-0 overflow-y-auto">
								<div className="flex min-h-full items-center justify-center p-4 text-center">
									<Transition.Child
										as={Fragment}
										enter="ease-out duration-300"
										enterFrom="opacity-0 scale-95"
										enterTo="opacity-100 scale-100"
										leave="ease-in duration-200"
										leaveFrom="opacity-100 scale-100"
										leaveTo="opacity-0 scale-95"
									>
										<Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white p-[40px] text-left align-middle shadow-xl transition-all max-sm:max-w-md lg:max-w-[764px]">
											{!isSubmitted ? (
												<>
													<div className="flex items-center gap-[24px] max-md:flex-col max-sm:flex-col lg:flex-row">
														<label
															htmlFor=""
															className="mb-6 flex w-full flex-col gap-2 max-md:mb-[0px] max-sm:mb-[0] lg:w-6/12"
														>
															<span className="text-base font-normal leading-6 text-black">
																Name{' '}
																<sup>*</sup>
															</span>
															<input
																type="text"
																name="name"
																value={
																	formData.name
																}
																onChange={
																	handleInputChange
																}
																className="form-input h-[48px] rounded border border-solid border-[#DDD] px-[20px]"
																placeholder="Full name"
															/>
															{errors.name && (
																<span className="text-red-500">
																	{
																		errors.name
																	}
																</span>
															)}
														</label>
														<label
															htmlFor=""
															className="mb-6 flex w-full flex-col gap-2 lg:w-6/12"
														>
															<span className="text-base font-normal leading-6 text-black">
																Contact number{' '}
																<sup>*</sup>
															</span>
															<input
																type="text"
																name="contact"
																value={
																	formData.contact
																}
																onChange={
																	handleInputChange
																}
																className="form-input h-[48px] rounded border border-solid border-[#DDD] px-[20px]"
																placeholder="0123456789"
															/>
															{errors.contact && (
																<span className="text-red-500">
																	{
																		errors.contact
																	}
																</span>
															)}
														</label>
													</div>
													<div className="flex items-center gap-[24px] max-md:flex-col max-sm:flex-col lg:flex-row">
														<label
															htmlFor=""
															className="mb-6 flex w-full flex-col gap-2 max-md:mb-[0px] max-sm:mb-[0px] lg:w-6/12"
														>
															<span className="text-base font-normal leading-6 text-black">
																Location{' '}
																<sup>*</sup>
															</span>
															<select
																name="location"
																value={
																	formData.location
																}
																onChange={
																	handleInputChange
																}
																className="form-select h-[48px] rounded border border-solid border-[#DDD] px-[20px]"
															>
																<option value="">
																	Select State
																</option>
																{StateData.map(
																	(state) => (
																		<option
																			key={
																				state.isoCode
																			}
																			value={
																				state.isoCode
																			}
																		>
																			{
																				state.name
																			}
																		</option>
																	),
																)}
															</select>
															{errors.location && (
																<span className="text-red-500">
																	{
																		errors.location
																	}
																</span>
															)}
														</label>
														<label
															htmlFor=""
															className="mb-6 flex w-full flex-col gap-2 lg:w-6/12"
														>
															<span className="text-base font-normal leading-6 text-black">
																Email{' '}
																<sup>*</sup>
															</span>
															<input
																type="email"
																name="email"
																value={
																	formData.email
																}
																onChange={
																	handleInputChange
																}
																className="form-input h-[48px] rounded border border-solid border-[#DDD] px-[20px]"
																placeholder="abc123@gmail.com"
															/>
															{errors.email && (
																<span className="text-red-500">
																	{
																		errors.email
																	}
																</span>
															)}
														</label>
													</div>
													<div className="flex items-center gap-[24px] lg:flex-row">
														<label
															htmlFor="profile-link"
															className="mb-6 flex flex-col gap-2 max-md:w-full max-sm:w-full lg:w-6/12 lg:max-w-[330px]"
														>
															<span className="text-base font-normal leading-6 text-black">
																Your profile
																link{' '}
																<sup>*</sup>
															</span>
															<input
																type="url"
																id="profile-link"
																name="profileLink"
																value={
																	formData.profileLink
																}
																onChange={
																	handleInputChange
																}
																className="form-input h-[48px] rounded border border-solid border-[#DDD] px-[20px]"
																placeholder="https://www.linkedin.com/in/yourprofile"
															/>
															{errors.profileLink && (
																<span className="text-red-500">
																	{
																		errors.profileLink
																	}
																</span>
															)}
														</label>
													</div>
													<div className="items-left flex flex-col">
														<label
															htmlFor=""
															className="mb-[40px] flex w-full flex-col gap-2"
														>
															<span>Message</span>
															<textarea
																name="message"
																value={
																	formData.message
																}
																onChange={
																	handleInputChange
																}
																className="form-textarea resize-none rounded border border-solid border-[#DDD] px-[20px]"
																placeholder="Upto 1000 words"
																rows={4}
															></textarea>
														</label>
														<button
															className="max-w-[116px] bg-[#EC691F] px-[32px] py-[20px] text-[16px] text-[#FFFFFF]"
															onClick={
																handleSubmit
															}
														>
															Submit
														</button>
													</div>
												</>
											) : (
												<>
													<div className="flex flex-col items-center justify-between gap-4">
														<h3 className="text-lg font-medium leading-6 text-gray-900">
															Application
															submitted
															successfully
														</h3>
														<button
															className="max-w-[116px] bg-[#EC691F] p-[20px] text-[16px] text-[#FFFFFF]"
															onClick={closeModal}
														>
															Close
														</button>
													</div>
												</>
											)}
										</Dialog.Panel>
									</Transition.Child>
								</div>
							</div>
						</Dialog>
					</Transition>
				</div>
			</motion.div>
		</div>
	);
};

export default JobOpeningsSection;
