import { motion } from 'framer-motion';
import { fadeLeft, fadeUp } from '../../../../utils/scrollAnimations';

const OnePlanetPeople = () => {
	const items = [
		'Environmental Sustainability',
		'Labour Practices, Health & Safety',
		'Consumer & Product Safety',
		'Social & Community Development',
	];

	return (
		<section className="xl1:px-[60px] xl:gap[80px] flex flex-col items-center gap-[40px] px-[18px] py-[80px] md:gap-[60px] md:px-[32px] md:py-[120px] xl:px-[110px]">
			<motion.img
				className="xl1:w-[979px] w-full md:w-[688px] xl:w-[927px]"
				src={`${process.env.PUBLIC_URL}/assets/img/planet-one-people.svg`}
				variants={fadeLeft}
				initial={'hidden'}
				whileInView="visible"
				viewport={{ once: true }}
				alt=""
			/>

			<motion.div
				className="flex flex-col items-center gap-[24px] xl:gap-[40px]"
				variants={fadeUp}
				initial={'hidden'}
				whileInView="visible"
				viewport={{ once: true }}
			>
				<span className="xl1:text-[40px] text-[24px] md:text-[32px]">
					We care for –
				</span>
				<div className="flex flex-wrap justify-center gap-[12px] md:gap-[20px] xl:gap-[24px]">
					{items.map((item, index) => {
						return (
							<div
								key={index}
								className="xl1:text-[24px] w-full rounded-[12px] bg-[#FFF9EF] px-[12px] py-[16px] text-center text-[16px] md:w-[calc(50%-10px)] xl:w-auto xl:px-[32px] xl:py-[20px] xl:text-[20px]"
							>
								{item}
							</div>
						);
					})}
				</div>
			</motion.div>
		</section>
	);
};

export default OnePlanetPeople;
