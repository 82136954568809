import { motion } from 'framer-motion';
import React from 'react';
import { fade, fadeLeft } from '../../../utils/scrollAnimations';
interface DolphinValuesCardProps {
	title: string;
	imageSrc: string;
	description: string;
}

const DolphinValuesCard: React.FC<DolphinValuesCardProps> = ({
	title,
	imageSrc,
	description,
}) => {
	return (
		<div className="dolphinValuesCard flex gap-10 pl-3">
			<div className="leftLine"></div>
			<div className="flex-grow">
				<div className="dark-gradient-text cardTitle mb-4 pt-5 text-[24px] font-extrabold sm:text-[40px] md:pt-10">
					<div className="circleDot"></div>
					{title}
				</div>
				<div className="flex flex-col justify-between gap-[24px] pb-[20px] md:flex-row md:items-center md:gap-10 md:pb-10">
					<div className="w-full md:w-5/12 lg:w-6/12">
						<motion.img
							src={imageSrc}
							alt=""
							className="w-full object-cover"
							variants={fade}
							initial={'hidden'}
							whileInView="visible"
							viewport={{ once: true }}
						/>
					</div>
					<motion.div
						className="w-full text-[16px] text-black md:w-7/12 md:text-[20px] lg:w-5/12 lg:text-2xl"
						variants={fadeLeft}
						initial={'hidden'}
						whileInView="visible"
						viewport={{ once: true }}
					>
						{description}
					</motion.div>
				</div>
			</div>
		</div>
	);
};

export default DolphinValuesCard;
