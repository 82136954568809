import { motion } from 'framer-motion';
import AchievementsCard from './AchievementsCard';
import { useEffect, useState } from 'react';
import { getAchievementsData } from '../../../utils/fetchFromApi';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PrevArrow from '../../Media/components/PrevArrow';
import NextArrow from '../../Media/components/NextArrow';

interface AchievementData {
  title: string;
  imageSrc: string;
}

const OurAchievements = () => {
	const [achievementsData, setAchievementsData] = useState<AchievementData[]>([]);

	useEffect(() => {
		getAchievementsData().then((data) => {
			const formattedData = data.map((item: any) => ({
				title: item.acf.title,
				imageSrc: item.acf.image,
			}));
			setAchievementsData(formattedData);
		});
	}, []);

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		prevArrow: <PrevArrow className="slick-prev" align="right" />,
		nextArrow: <NextArrow className="slick-next" align="right" />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	return (
		<div className="mx-auto max-w-japfaGrid max-mediumScreen:px-14 max-lg:px-8 max-sm:pb-[56px] md:pb-[80px] lg:pb-[120px]">
			<h2 className="text-5xl font-extrabold text-black max-md:text-[40px] max-sm:mb-[40px] max-sm:text-[38px] md:mb-[40px] md:text-[40px] lg:mb-[60px] lg:text-[72px]">
				Our Achievements
			</h2>
			<motion.div>
				{achievementsData.length > 0 && (
					<Slider {...settings}>
						{achievementsData.map((achievement, index) => (
							<div key={index} className="px-3">
								<AchievementsCard
									title={achievement.title}
									imageSrc={achievement.imageSrc}
								/>
							</div>
						))}
					</Slider>
				)}
			</motion.div>
		</div>
	);
};

export default OurAchievements;
